export const colorsTheme = {
  white: '#FFFFFF',
  black: '#000000',
  danger: '#F2383A',

  'gradient-deg': '294deg',
  'gradient-primary': '#010B13',
  'gradient-secondary': '#010B13',

  'primary-color': '#198754',
  'secondary-color': '#12613C',
  'auxiliary-color': '#20C997',

  'bg-color': '#FFFFFF',
  'bg-secondary-color': '#FFFFFF',
  'bg-auxiliary-color': '#343A40',

  'bg-circle-color': '#000',
  'bg-circle-hover': '#20C997',
  'bg-circle-text-color': '#000',
  'bg-circle-text-color-hover': '#000',

  'filter-button-background': '#198754',
  'filter-button-color': '#FFFFFF',
  'filter-button-border': '#198754',
  'entry-message-color': '#198754',

  'bg-modal-color': '#343A40',

  'dark-color': '#3F3F3F',

  'text-primary': '#FFFFFF',
  'text-secondary': '#FFFFFF',
  'text-tertiary': '#FFFFFF',
  'text-gray': '#4F4F4F',

  'input-color': '#FFFFFF',
  'bg-input-color': '#FFFFFF',
  'info-color': '#6c757d',
  'disabled-color': '#6c757d',
  'card-color': '#495057',
  'modal-border-color': '#ADB5BD',
  'border-color': '#FFFFFF',

  'echeck-label-color': '#000',
  'echeck-border-color': '#D2D2D2',
  'echeck-text-color': '#000',
  'echeck-bar-color1': '#198754',
  'echeck-bar-color2': '#198754'
}
